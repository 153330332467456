<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_empresa.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_empresa.UY.name')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_empresa.UY.name')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_empresa.UY.surename')"
            :error="errors.get('apellidos')"
          >
            <el-input
              ref="apellidos"
              v-model="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_empresa.UY.surename')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.SP.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.SP.identity_doc')"
            type-hidden
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_empresa.UY.phone')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_empresa.UY.phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="provincia"
            :label="$t('forms_flujo.form1_empresa.UY.state')"
            :error="errors.get('provincia')"
          >
            <el-select
              ref="provincia"
              v-model="formulario1.provincia"
              :placeholder="$t('forms_flujo.form1_empresa.UY.state')"
            >
              <el-option
                v-for="item in departamentos"
                :key="item.departamento"
                :label="item.departamento"
                :value="item.departamento"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.form1_empresa.UY.email')"
            :error="errors.get('email')"
          >
            <el-input
              ref="email"
              v-model="formulario1.email"
              type="text"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form1_empresa.UY.email')"
              @change="errors.clear('email')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <pz-check-form />
      <el-row :gutter="20">
        <el-col
          v-for="(condition) in producto.conditions"
          :key="condition.id"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-between"
        >
          <pz-terms-and-conditions
            v-model="formulario1.conditions[condition.slug]"
            :prop="'condition_'+condition.slug"
            :name="condition.nombre"
          >
            <div v-html="condition.contenido" />
          </pz-terms-and-conditions>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
import {required} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import {getDepartamentos} from '@/routes/api/resources';
import baseForm1 from '@/components/Flujo/Formularios/Formularios1/baseForm1';
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";

export default {
    name: 'PzCompanyForm1Uy',
    components: {PzTermsAndConditions, PzFormFlujo, PzCheckForm, PzIdentityDocument},
    mixins: [baseForm1],
    provide: function () {
        return {
            myForm: this,
        }
    },
    data() {
        return {
            reference: 'formulario1',
            loading: false,
            marital_status: [],
            centerDialogVisible: false,
            departamentos: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                provincia: '',
                telefono: '',
                email: '',
                ref_empresa: process.env.COMPANY_KEY,
                identity: {document: 1},
            },
            disable: true,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required('')],
                nombres: [required('')],
                apellidos: [required('')],
                provincia: [required('')],
                telefono: [required('')],
            },
        };
    },
    created() {
        this.fetchData();
    },
    mounted() {
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let departamento = getDepartamentos().then((res) => {
                this.departamentos = res.data;
            }).catch(() => {
                this.departamentos = [];
            });
            return Promise.all([departamento]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    },

}
</script>

